import React, { useState } from 'react';
import ProfileDetails from './ProfileDetails';
import { useSelector } from 'react-redux';
import helper from '../helper';
import ManagerProfile from './ManagerProfile';
import avatar from '../static/images/avatar.png'
export default function ProfileIcon() {
    const storeData = useSelector(state => state)
    const [open, setOpen] = useState(false)
    return (
        <>
            <span className="all-center h-100 pointer">
                <img width="40px" height="40px"
                    className="rounded-circle"
                    src={storeData.profilePic ? storeData.profilePic : avatar} alt=""
                    onClick={() => setOpen(true)} />
            </span>
            {open && helper.getRole() === "admin" &&
                <ProfileDetails
                    CallClose={() => setOpen(false)}
                />}
            {open && helper.getRole() === "manager" &&
                <ManagerProfile
                    CallClose={() => setOpen(false)}
                />}
        </>);
}
