import React, { useState } from 'react'
import arrow1 from '../../static/images/slidearrow1.svg'
import arrow2 from '../../static/images/slidearrow2.svg'

export default function PreviewSlides({ data, cb, startIndex }) {
  
    const [isFull,setIsFull]  = useState(false)
    const [state, setState] = useState({
        currentIndex: startIndex,
        noOfSlides: data.length
    })

    function fullScreen(isFull) {
        try{
            if (!isFull) {
               setIsFull(true)
                const ob = document.getElementById("syllab-fullscreen");
                ob.requestFullscreen();
            } else {
                setIsFull(false)
                document.exitFullscreen();
            }
           }catch(err){
               
           }
    
    }

    window.addEventListener('keyup',(e)=>{
        var key = e.which;
        if(key == 13 || key == 39) { 
            setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex }) 
  
             return false;  
        } else if(key == 37) { 
            setState({ ...state, currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : state.currentIndex }) 
            return false;  
        }
    })
    return (
        <>

            <div className="popup-modal all-center">
                <div id="syllab-fullscreen" className="popup-modal-body md px-2 ">
                    <div className='py-2 bg-white p-sticky tp-0' ></div>
                    <section className='my-3'>
                    <div className='all-center' style={{minHeight:'450px'}}>
                            <img style={{maxWidth:'100%'}} className='h-100 br-1' src={data[state.currentIndex]} alt="" />
                        </div>
                    </section>
                    <section className='all-center mb-2 py-2 bg-white p-sticky bt-0'>
                        <div className='all-center'>
                            <img src={arrow1} className="mx-2 pointer" alt="" onClick={() => { setState({ ...state, currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : state.currentIndex }) }} />
                            <span className='f-fred fs-20 tx-lt-black mx-1'>Slide {state.currentIndex + 1} of {data.length}</span>
                            <img src={arrow2} className="mx-2 pointer" onClick={() => { setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex }) }} alt="" />
                        </div>
                        <section className='p-rel c-dropdown d-inline-block'>
                            <input type="checkbox" className='c-drop-icon d-none' id="drop-icon" />
                            <label htmlFor="drop-icon"> <i class="fas fa-ellipsis-v c-dropdown-icon pointer ml-2 hover-shadow"></i></label>
                            <div className='p-abs c-dropdown-body  fs-16  ms-700 rt-0 bg-white' style={{ width: '190px' }}>
                                <ul style={{ listStyle: 'none' }}>
                                    <li style={{ listStyle: 'none' }}><label htmlFor="drop-icon" onClick={cb}> CLOSE</label></li>
                                    <li><label htmlFor="drop-icon" onClick={()=>fullScreen(isFull)}>{isFull ? "EXIT FULLSCREEN" : " FULLSCREEN"}</label></li>
                                </ul>
                            </div>
                        </section>
                    </section>

                </div>
            </div>

        </>
    )
}
