import React, { useState, useRef, useEffect } from 'react'
import { FiXCircle } from "react-icons/fi";
import admin from '../controller/admin';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import helper from '../helper';
import { Toaster, toast } from 'react-hot-toast';
import manager from '../controller/manager';
import Backdrop from '../component/Backdrop';
export default function EditManagerDetails({ CallClose, onUpdate, data }) {
    const managerForm = useRef()
    const password = useRef()
    const newPassword = useRef()
    const confirmNewPassword = useRef()
    const [state, setState] = useState({
        isActive: data.isActive,
        pic: null,
        load: false

    })
    const [preSelectedManagerList, setPreSelectedManagerList] = useState([])

    const submit = async () => {
        const form = new FormData(managerForm.current);
        form.append('role', 4)
        form.append('isActive', state.isActive)
        form.append('adminId', helper.getid())
        form.append('id', data.managerId)
        if (!form.get('name')) {
            toast("please enter name")
            return
        }

        let resp;
        //change password
        const passwordReq = {
            "managerId": helper.getid(),
            "oldPassword": password.current.value,
            "newPassword": newPassword.current.value
        }
        if (passwordReq.oldPassword || passwordReq.newPassword) {
            resp = helper.validate("password", passwordReq.oldPassword)
            if (!resp.isValid) {
                toast(resp.msg)
                return
            }
            if (!passwordReq.newPassword) {
                toast('please enter new password')
                return
            }
            resp = helper.validate("password", passwordReq.newPassword)
            if (!resp.isValid) {
                toast(resp.msg)
                return
            }
            if (passwordReq.newPassword !== confirmNewPassword.current.value) {
                toast('password mismatch')
                return
            }
        }
        // return
        setState({ ...state, load: true })
        await manager.editManagerDetails(form).then(async (res) => {
            if (res.data.status) {
                if (passwordReq.oldPassword) {
                    await manager.changePassword(passwordReq).then((resp) => {
                        if(!resp.data.status){
                            toast(res.data.error)
                        }
                    })
                }
                toast("Details successfully updated");
                setTimeout(() => {
                    CallClose()
                    onUpdate(res.data.result)
                }, 1000)
            } else {
                toast(res.data.error)
            }
            setState({ ...state, load: false })
        })
    }

    return (<>
      
        <div className="manager">
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Edit <span style={{ color: "#089D12" }}> details</span></p>
                            <p className="ms-500 " style={{ color: "#434943", fontSize: "14px" }}>SayDhad manager</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    <form ref={managerForm} onSubmit={(e) => { e.preventDefault(); submit() }}>
                        <section className="mt-3 px-3 edit-profile f-fred fs-14">
                            <div className="mb-3">
                                <label class="switch">
                                    <input type="checkbox" defaultChecked={state.isActive ?  true  :false} onChange={(e) => { setState({ ...state, isActive: e.target.checked }) }} />
                                    <span class="slider round"></span>
                                </label>
                                <span className="pl-2 fs-18">{state.isActive ? "Active" : "Inactive"}</span>
                            </div>
                            <div className="mb-2 edit-input">
                                <input name="name" defaultValue={data.name} type="text" placeholder="Manager name" className="w-100 br-1" />
                            </div>
                            <div className="mb-2 edit-input">
                                <input name="email" value={data.email} type="text" placeholder="Manager email" className="w-100 p-2 br-1" />
                            </div>
                            <div className="mb-2 edit-input">
                                <input name="mobileNo" value={data.mobileNo} maxLength={10} type="text" placeholder="mobile No" className="w-100 p-2 br-1" />
                            </div>
                            <div className=" edit-input">
                                <input ref={password} placeholder="old password" type="password" />
                                <input ref={newPassword} placeholder="New password" type="password" />
                                <input ref={confirmNewPassword} placeholder="Repeat new password" type="password" />
                            </div>


                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <label htmlFor="image" className="btn bg-green text-white ms-700 fs-14">Select profile picture</label>
                                    <input name="profilePicture" type="file" id="image" className="d-none" onChange={(e) => { setState({ ...state, pic: e.target.files[0] }) }} />
                                </div>
                                <div>
                                    {
                                        state.pic ?
                                            <img width="50px" height="50px" className="rounded-circle" src={URL.createObjectURL(state.pic)} />
                                            :
                                            <img width="50px" height="50px" className="rounded-circle" src={data.profilePicture} />


                                    }
                                </div>
                            </div>

                            <div>
                                <button type="submit" className="btn btn-update py-1" >SUBMIT</button>
                            </div>

                        </section>
                    </form>

                </div>
            </div>
            {state.load && <Backdrop />}
        </div>
    </>);
}
