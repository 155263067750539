import React from 'react';
import ThreeDot from '../../components/ThreeDot';
import avatar from '../../static/images/avatar.png'
export default function AttendanceCard({ name, course, country, callDuration, time, date, onChat,onVideo, img }) {
    return (<>
        <section className={`notify-card p-rel fs-14 b-grey tx-lt-grey p-2 my-2 row gx-0 mx-0 fs-14 ms-500`}>
            <div className="col-lg-2  col-md-4  col-6 d-flex justify-content-start align-items-center mb-2">
                <img width="50px" height="50px" className="rounded-circle mr-2" src={img ? img : avatar} alt="" />
                <div className="mx-3">
                    <p className="f-fred lh-15 ">{name}</p>
                    <p className="ms-500 fs-12">student</p>
                </div>
            </div>
            <div className="col-lg-1 col-md-4 d-none col-4 d-lg-flex d-md-flex align-items-center mb-2">
                <div className="mx-2">
                    <p className="f-fred lh-15">{country}</p>
                    <p className="ms-500 fs-12">conutry</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6 d-flex align-items-center mb-2">
                <div className="mr-3">
                    <p className="f-fred lh-15">{course ? course : "N/A"}</p>
                    <p className="ms-500 fs-12">course</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center mb-2">
                <div className="mr-3">
                    <p className="f-fred lh-15">{callDuration}</p>
                    <p className="ms-500 fs-12">call duration</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center mb-2">
                <div className="mr-3">
                    <p className="f-fred lh-15">{date}</p>
                    <p className="ms-500 fs-12">schedule</p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 d-none col-4 d-lg-flex d-md-flex align-items-center justify-content-end mb-2">
                <div className="mr-2">
                    <p className="f-fred lh-15">{time}</p>
                    <p className="ms-500 fs-12">Time</p>
                </div>
                <div>
                <ThreeDot onVideo={() => { onVideo() }} onChat={() => onChat()} />
                </div>
            </div>
            {/* <div className="col-lg-1 px-1 col-12  all-center justify-content-end">
                <ThreeDot onVideo={() => { onVideo() }} onChat={() => onChat()} />
            </div> */}
        </section>
    </>);
}
