import React from 'react'

export default function ArticleCard({img,title,subTitle,onChange}) {
    return (
        <div className="course-card b-grey px-2 py-2" >
        <div className="course-img br-1 mb-2 pointer" style={{height:'160px',overflow:'hidden'}} onClick={onChange && onChange}>
            <img className="w-100 h-100 br-1" src={img} alt="" />
        </div>
        <div className="course-body mt-3 mb-2">
            <p className="fs-18 overflow-elipse f-fred text-capitalize w-100" style={{overflow:'hidden',whiteSpace:'nowrap'}}>{title}</p>
            <p className="fs-12  overflow-elipse ms-500 text-capitalize w-100" style={{overflow:'hidden',whiteSpace:'nowrap'}}>{subTitle}</p>
           
        </div>
    </div>
    )
}
