import React from 'react';
import moment from 'moment';
import avatar from '../../static/images/avatar.png'
export default function ReferralDetailCard({ plan, onClick }) {
  
    return (
        <>
            <div className="quote-card pointer bg-smoke border-radius-1 py-2 my-2" onClick={onClick}>
                <div className="row gx-0 fs-14 tx-lt-grey mx-0">
                    <div className="col-lg-2 d-flex align-items-center px-1 my-1">
                        <img width={'40px'} height={'40px'} className="rounded-circle mr-2" src={plan.profilePicture ? plan.profilePicture : avatar} alt="" />
                        <div className="">
                            <p className="f-fred ">{plan.name ? plan.name : 'N/A'}</p>
                            <p className="ms-500 fs-12 ">Name</p>

                        </div>
                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{plan.country}</p>
                        <p className="ms-500 fs-12 ">Country</p>

                    </div>

                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{plan.student_id}</p>
                        <p className="ms-500 fs-12 ">Student ID</p>
                    </div>

                    <div className="col-lg-4 px-2 my-1">
                        <p className="f-fred text-right">{plan.referral_minutes ? plan.referral_minutes : 0}</p>
                        <p className="ms-500 fs-12 text-right">Minutes</p>

                    </div>
                    <div className="col-lg-2 px-2 my-1">
                        <p className="f-fred text-right">{moment(plan.created_at).format('DD MMM YYYY')}</p>
                        <p className="ms-500 fs-12 text-right">Enrolled on</p>

                    </div>
                    <div className="col-lg-2 px-2 my-1">
                        <p className="f-fred text-right">{moment(plan.created_at).format("hh : mm a")}</p>
                        <p className="ms-500 fs-12 text-right ">Time</p>

                    </div>
                </div>
            </div>
        </>
    )
}
