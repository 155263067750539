import React, { useState, useRef } from 'react'
import logo from '../static/images/Logo.svg'
import helper from '../helper/index'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import { useHistory } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
export default function AdminFogotPassword() {
    const history = useHistory()
    const email = useRef()
    const [open, setOpen] = useState(false)
    const submit = async () => {
        const data = {
            email: email.current.value,
        }
        const response = helper.validate("email", data.email)
        if (!response.isValid) {
            toast(response.msg)
            return
        }
        setOpen(true)
        await auth.adminForgotPassword(data)
            .then((res) => {
                if (res.data.status) {
                    toast(res.data.result)
                } else {
                    toast(res.data.error.message)
                }
              
                setOpen(false)
            })

    }


    return (
        <div className="user-little-more auth-background tutor-login-home all-center flex-column" >
            <div className="row gx-0 mx-0 bg-white br-1 p-4" >
                <div className="col-lg-12 col-md-8 col-12 px-3 mx-auto all-center flex-column">
                    <div className="all-center  mb-3">
                        <img width="220px" src={logo} alt="" />
                    </div>
                    <div className="f-fred  m-4" style={{ minWidth: '300px' }}>
                        <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                            <h4 className="f-fred my-2">Forgot <span className="tx-green">password</span></h4>

                            <div className="input-box mb-2 mt-3 mb-3">
                                <input ref={email} type="text" placeholder="email" className="px-2 py-2 w-100 ms-700 " />
                            </div>
                            <button type="submit" className="btn btn-login fs-12 mt-2 d-block  mx-auto w-100"  >PROCEED</button>
                            <p className='text-center ms-500 mt-3 fs-14'>Click here to <span className='tx-green ms-700 pointer link' onClick={() => history.push('/')}>Admin login</span> </p>

                        </form>
                    </div>

                </div>
            </div>
            {open && <Backdrop />}
        </div>
    )
}
