import React, { useState } from 'react'
import arrow1 from '../../static/images/slidearrow1.svg'
import arrow2 from '../../static/images/slidearrow2.svg'
import Slide from 'react-reveal/Slide';
export default function NewSlideShow({ data, cb, startIndex }) {
    const [gridView, setGridView] = useState(true)
    const [isFull, setIsFull] = useState(false)
    const [state, setState] = useState({
        currentIndex: startIndex,
        noOfSlides: data.length
    })

    function fullScreen(isFull) {
        try {
            if (!isFull) {
                setIsFull(true)
                const ob = document.getElementById("syllab-fullscreen");
                ob.requestFullscreen();
            } else {
                setIsFull(false)
                document.exitFullscreen();
            }
        } catch (err) {

        }

    }

    return (
        <>

            <div className="popup-modal all-center">
                <div id="syllab-fullscreen" className="popup-modal-body  p-rel md px-2 hide-scroll">
                    <div className='py-2 bg-white p-sticky tp-0 ' >
                        <p className='text-right f-fred pointer mr-4 tx-red' onClick={cb}>CLOSE</p>
                    </div>

                    <section className='my-3'>
                        <div className='all-center' style={{ minHeight: '450px' }}>
                            <img style={{ maxWidth: '100%' }} className='h-100 br-1' src={data[state.currentIndex].fileName} alt="" /> :
                        </div>
                    </section>
                    {
                        !gridView ?
                            <Slide bottom>
                                <div className='p-abs bt-0 lt-0 w-100'>
                                    <section className='row gx-0 mb-1 mx-0 py-0 bg-white'>
                                        <section className='col-lg-7 col-md-7 d-flex align-items-center col-12 mb-2 mt-2'>
                                            <p className='ms-700 tx-lt-grey h-100 mx-2 overflow-elipse'>{state.currentIndex + 1}. {data[state.currentIndex].title} </p>
                                        </section>
                                        <section className='col-lg-5 col-md-7 col-12 mb-2 mt-2'>
                                            <div className='all-center'>
                                                <img src={arrow1} className="mx-2 pointer hover-shadow" alt="" onClick={() => { setState({ ...state, currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : state.currentIndex }) }} />
                                                <span className='f-fred fs-20 tx-lt-black mx-1'>Slide {state.currentIndex + 1} of {data.length}</span>
                                                <img src={arrow2} className="mx-2 pointer hover-shadow" onClick={() => { setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex }) }} alt="" />

                                                <section className='p-rel c-dropdown  d-inline-block'>
                                                    <input type="checkbox" className='c-drop-icon d-none' id="drop-icon" />
                                                    <label htmlFor="drop-icon"> <i class="fas fa-ellipsis-v c-dropdown-icon pointer ml-2 hover-shadow"></i></label>
                                                    <div className='p-abs  c-dropdown-body bg-sky  fs-16  ms-700 bg-white'
                                                        style={{
                                                            width: '190px', bottom: '25px',
                                                            left: '-190px', top: 'initial'
                                                        }}>
                                                        <ul style={{ listStyle: 'none' }}>

                                                            <li onClick={() => fullScreen(isFull)}><label htmlFor="drop-icon">{isFull ? "EXIT FULLSCREEN" : " FULLSCREEN"}</label></li>
                                                            <li onClick={() => setGridView(!gridView)}><label htmlFor="drop-icon" >{!gridView ? "OPEN GRID" : "CLOSE GRID"}</label></li>
                                                        </ul>
                                                    </div>
                                                </section>
                                            </div>
                                        </section>

                                    </section>
                                </div>
                            </Slide>
                            :
                            <Slide bottom>
                                <div className='p-abs bt-0 lt-0 w-100'>
                                    <section className='d-flex w-100 align-items-center hide-scroll' style={{ overflowX: 'auto', backgroundColor: 'rgba(128, 128, 128, 0.418)' }}>
                                        {data.map((file, index) =>
                                            <div className={`${state.currentIndex === index ? "border-sky" : "border-yellow"} br-1 bg-white mx-2 d-inline-block my-2 pointer`}
                                                onClick={() => { setState({ ...state, currentIndex: index }) }}>


                                                <img style={{ width: '150px', height: '100px' }} className='br-1' src={file.fileName} alt="" /> :


                                                <div style={{ width: '150px' }}>
                                                    <p className='fs-14 ms-700 my-2 text-center overflow-elipse px-1'>{file.title}</p>

                                                </div>
                                            </div>)}
                                        <i className='fas p-abs tp-0 rt-0  fa-times d-inline-block fs-20 hover-shadow pointer' onClick={() => setGridView(!gridView)}></i>
                                    </section>
                                </div>
                            </Slide>
                    }
                </div>
            </div>

        </>
    )
}
