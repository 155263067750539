import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import logo from '../static/images/Logo.svg'
import auth from '../controller/auth'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import { Toaster, toast } from 'react-hot-toast'
import ToggleHidePassword from '../components/ToggleHidePassword'
export default function ManagerLogin() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false)
    useEffect(() => {

        const response = helper.isLoggedIn()
        if (response.res) {
            history.push(`/manager/dashboard/home`)
        }

    }, [])
    const submit = () => {
        const data = { email: email, password: password }
        let resp;
        resp = helper.validate("email", email)
        if (!resp.isValid) { toast(resp.msg); return; }
        helper.validate("password", password)
        if (!resp.isValid) { toast(resp.msg); return; }
            setOpen(true)
            auth.managerLogin(data)
                .then((res) => {
                    if (res.data.status) {
                    
                        helper.saveCredential(res.data.result.token, res.data.result.id, res.data.result.email, res.data.result.userType,res.data.result.accessModule)
                        history.push(`/manager/dashboard/home`)
                    }else{
                        toast(res.data.error)
                    }
                    setOpen(false)
                })
        
    }


    return (
        <div className="tutor-login-home ">
        
            <section className='auth-background w-100 h-100'>
                <section className="row gx-0 mx-0 h-100 cpx-4 py-5 " >
                    <div className="col-lg-8"></div>
                  
                    <div className="col-lg-4 bg-white br-2 col-md-5  px-3  all-center" >
                        
                        <div className="row gx-0 mx-0 w-100 bg-white px-3 py-3 my-3 login-home-box">
                            <div className="col-lg-8 mx-auto d-flex flex-column justify-content-between">
                                <div className="upper">
                                    <div className="all-center ">
                                        <img width="250px" src={logo} alt="" />
                                    </div>
                                    <div className="all-center flex-column">
                                        <section className='ms-700 fs-18' style={{ minWidth: '300px' }}>
                                            <p className="ms-900 text-center fs-24 my-4">MANAGER <span className="tx-green">LOGIN</span></p>
                                            <div className="mt-1 w-100 ms-700 fs-18">
                                                <div className="input-box mb-2 ">
                                                    <input type="email" placeholder="email" className="px-2 py-2" onChange={(e) => { setEmail(e.target.value);}} />
                                                </div>
                                               
                                            <ToggleHidePassword onChange={(e) => { setPassword(e.target.value);}} />
                 
                                            </div>
                                              <button className="btn btn-tutor-profile my-2 px-2 fs-12 w-100" onClick={() => submit()}>PROCEED</button>
                                            <p className='text-center ms-500 mt-3 fs-14'>Click here to <span className='tx-green ms-700 pointer link' onClick={()=>history.push('/')}>Admin login</span> </p>
                                            <p className='text-center ms-500 mt-3 fs-14'> <span className='tx-green ms-700 pointer link' onClick={()=>history.push('/manager/forgot-password')}>Forgot password?</span> </p>
                                 
                                        </section>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </section>
            {open && <Backdrop />}
        </div>
    )
}
