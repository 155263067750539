import React from 'react'

export default function CourseCard({img,name,subTitle,lesson,slides,isRemove,onRemove,onChange}) {
    return (
        <div className="course-card b-grey px-2 py-2" >
            <div className="course-img br-1 mb-2 pointer" style={{height:'180px',overflow:'hidden'}} onClick={onChange && onChange}>
                <img className="w-100 h-100 br-1" src={img} alt="" />
            </div>
            <div className="course-body ">
                <p className="f-fred text-capitalize w-100 fs-18 overflow-elipse" >{name}</p>
                <p className="fs-12 ms-500 text-capitalize w-100 overflow-elipse" >{subTitle}</p>
                <div className="d-flex justify-content-between align-items-center">
                    <p className="fs-12 ms-700">{lesson} Lessons <span className="ms-900">{slides} slides</span></p>
                   {isRemove && <span className="px-1 py-1 fs-12 reshedule-btn ms-700 pointer" onClick={onRemove}>Remove</span>}
                </div>
            </div>
        </div>
    )
}
