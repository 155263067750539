import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'
import { useHistory, useParams } from 'react-router-dom'
import Backdrop from '../../component/Backdrop'
import ChatBox from '../../component/ChatBox'
import ProfileIcon from '../../components/ProfileIcon'
import { getChatUserListing } from '../../controller/tutor'
import helper from '../../helper'
import avatar from '../../static/images/avatar.png'
export default function ChatLogs() {
    const { userId } = useParams()
    const history = useHistory()
    const [state, setState] = useState({ data: [], pageload: true, error: null, tempData: [] })
    const [countList, setCountList] = useState([])
    const [chat, setChat] = useState({
        open: false,
        student_id: -1,
        tutor_id: -1,
        room_id: -1,
        name: ''
    })

    useEffect(() => {
        getStudents()
        return () => { }
    }, [])

    const getStudents = async () => {
        await getChatUserListing(userId)
            .then((res) => {
                if (res.data.status) {
                    if (res.data.result.length > 0) {
                        setChat({
                            ...chat,
                            open: true,
                            name: res.data.result[0].name,
                            student_id: res.data.result[0].student_id,
                            tutor_id: res.data.result[0].tutor_id,
                            room_id: res.data.result[0].chat_room_id
                        });
                        let list = []
                        res.data.result.forEach((data) => {
                            try {
                                let count = data.count.tutor ? data.count.tutor : 0
                                list.push(count)
                            } catch (err) {
                                list.push(0)
                            }
                        })
                        setCountList(list)
                    }
                    let temp = [...res.data.result]
                    let limit = 7
                    setState({ ...state, data: res.data.result, pageload: false, tempData: temp.splice(0, limit) })
                } else {
                    setState({ ...state, data: [], pageload: false, error: res.data.error })
                }
            }).catch((err) => {
                setState({ ...state, data: [], pageload: false, error: err })
            })
    }

    const handleChatPage = (page) => {
        let temp = [...state.data]
        let limit = 7
        let skipResult = (page - 1) * limit
        setState({ ...state, tempData: temp.splice(skipResult, limit) })

    }

    return (
        <div className="chat-logs mx-1">
            <header className='row gx-0 mx-0 mb-2 py-1'>
                <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center  px-1">
                    <button className='btn btn-back mr-2' onClick={() => history.goBack()}>BACK</button>
                    <div>
                        <p className="mb-1 fs-24 f-fred">Chat <span className="tx-green">Logs</span></p>
                        <p className="fs-14 ms-700 lh-15 sub-heading">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                </div>
                <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                    <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                        <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                    </p>
                    <ProfileIcon />
                </div>
            </header>

            <section className="chat-container row gx-0 mx-0">
                <div className="col-lg-4 col-md-5 chat-user-list-box hide-scroll pl-0">
                    {
                        state.tempData.map((result, index) => {
                            return (
                                <section key={index} className={`chat-student-card ${chat.student_id === result.student_id && "active-tutor-card"} d-flex b-grey p-2 align-items-center mb-2`} onClick={() => {
                                    setChat({
                                        ...chat,
                                        open: true,
                                        name: result.name,
                                        student_id: result.student_id,
                                        tutor_id: result.tutor_id,
                                        room_id: result.chat_room_id
                                    });
                                    let list = [...countList]
                                    list[index] = 0
                                    setCountList(list)
                                }}>
                                    <img width="40px" height="40px" className="rounded-circle mr-2" src={result.profilePicture ? result.profilePicture : avatar} alt="" />
                                    <div className='d-flex w-100 justify-content-between align-items-center'>
                                        <div className="mr-3">
                                            <p className="f-fred ">{result.name ? result.name : "Anonymous"}</p>
                                            <p className="ms-700 lh-18 fs-12"> Student </p>
                                        </div>
                                    </div>
                                </section>
                            )
                        })
                    }
                    {/* Pagination  */}
                    {state.data.length !== 0 &&
                        <div className="col-12 px-2 c-pagination">
                            <ReactPaginate
                                previousLabel={<i class="fas fa-arrow-left"></i>}
                                nextLabel={<i class="fas fa-arrow-right"></i>}
                                pageCount={Math.ceil(state.data.length / 10)}
                                onPageChange={({ selected }) => {
                                    handleChatPage(selected + 1)
                                }}
                                containerClassName={"paginationBttns"}
                                previousClassName={"previousBttn"}
                                nextClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                pageClassName={"eachpage"}
                            />
                        </div>
                    }
                    {state.data.length === 0 && <p className='my-2 text-center ms-500'>Chat not started yet</p>}
                </div>
                <div className="col-lg-6 col-md-7 mb-2 d-lg-block d-md-block d-none">
                    {chat.open && <ChatBox userName={chat.name} myId={chat.tutor_id} roomId={chat.room_id} remoteUserId={chat.student_id} />}
                </div>

                {state.pageload && <Backdrop />}

            </section>
        </div>
    )
}
