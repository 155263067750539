import React from 'react';
import avatar from '../../static/images/avatar.png'
import moment from 'moment';
export default function PayoutDetailCard({ userName, tutorName, userImg, tutorImg, amount, rate, callOn, time, callDuration }) {
    return (<>
        <div className="col-lg-12 px-0 col-md-12 col-12">
            <div className="quote-card fs-14 tx-lt-grey cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                <div className="row gx-0 mx-0">
                    <div className="col-lg-2 d-flex align-items-center px-1 my-1">
                        <div className="px-2 my-1 mr-2">
                            <span className="  pointer" >
                                <img width="40px" height="40px" className="rounded-circle" src={userImg ? userImg : avatar} alt="" /></span>
                        </div>
                        <div className="">
                            <p className="f-fred">{userName}</p>
                            <p className="ms-500 fs-12 ">Student's name</p>
                        </div>
                    </div>
                    <div className="col-lg-2 d-flex align-items-center px-1 my-1">
                        <div className="px-2 my-1 mr-2">
                            <span className="pointer" ><img width="40px" height="40px" className="rounded-circle" src={tutorImg ? tutorImg : avatar} /></span>
                        </div>
                        <div className="">
                            <p className="f-fred">{tutorName}</p>
                            <p className="ms-500 fs-12 ">Tutor's name</p>
                        </div>
                    </div>
                    <div className="col-lg-4 px-1 d-flex align-items-center">
                        <div className="px-2 my-1">
                            <p className="f-fred ">{callDuration}</p>
                            <p className="ms-500 fs-12">Call duration</p>
                        </div>
                        <div className="px-2 my-1">
                            <p className="f-fred ">{amount}</p>
                            <p className="ms-500 fs-12">Amount (SAR)</p>
                        </div>
                        <div className="px-2 my-1">
                            <p className="f-fred">{rate}</p>
                            <p className="ms-500 fs-12">Fee (SAR)</p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="px-2 my-1">
                            <p className="f-fred text-right">{moment(callOn).format('MMM DD, YYYY')}</p>
                            <p className="ms-500 fs-12 text-right">Call on</p>
                        </div>

                    </div>
                    <div className="col-lg-2">
                        <div className="px-2 my-1">
                            <p className="f-fred text-right">{moment(time, 'HH:mm:ss').format('hh:mm:ss a')}</p>
                            <p className="ms-500 fs-12 text-right">Time</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
