import React from 'react'
import moment from 'moment'
import ReactStars from "react-rating-stars-component";
import avatar from '../../static/images/avatar.png'
export default function TestimonialCard({ userName, rate, review, country, date, pic }) {
    return (
        <div className="d-flex my-2 mb-3">
            <div className="mr-2">
                <img style={{ height: '30px', width: '30px' }} className="rounded-circle my-2" src={pic ? pic : avatar} alt="tutor" />
            </div>
            <div className="cfs-14">
                <p><span className="f-fred lh-15">{userName ? userName : "Unknown"}</span> <span className='ms-500 tx-lt-black'>{country ? country : "---"}</span> </p>
                <p className="tx-lt-black lh-15 ms-500 fs-12">{moment(date).format('MMM DD,YYYY hh:mm A')}</p>
                <ReactStars
                    edit={false}
                    value={rate ? parseInt(rate).toFixed(0) : 0}
                    count={5}
                    size={15}
                    classNames="my-0 py-0"
                    activeColor="#ffcb00"
                />
                <p className="text-justify lh-15 ms-300 fs-14">
                    {review}
                </p>
            </div>
        </div>
    )
}
