import React from 'react';
import moment from 'moment';
import avatar from '../../static/images/avatar.png'
export default function IncompleteStudentCard({ data, onClick }) {
    return (
        <>
            <div className="col-lg-12 col-md-12 col-12 px-1">
                <div className="quote-card cur-pointer tx-lt-grey f-fred fs-14 bg-smoke border-radius-1 py-2 my-2 ">
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-2 col-md-3 col-6 px-2 my-1 d-flex align-items-center mb-2">
                            <span className=" mx-2 pointer" >
                                <img width="40px" height="40px" className="rounded-circle" src={data.profilePicture ? data.profilePicture : avatar} /></span>
                            <div className="px-2">
                                <p className="">{data.name ? data.name : 'NA'}</p>
                                <p className="ms-500 fs-12">Student</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex align-items-center mb-2">
                            <div className="mx-2">
                                <p className="">{data.country ? data.country : 'NA'}</p>
                                <p className="ms-500 fs-12">Country</p>
                            </div>
                        </div>
                      

                        <div className="col-lg-3 col-md-3  col-6 d-flex align-items-center mb-2 flex-wrap">
                            <div className="d-flex flex-column flex-wrap">
                                <p className="">{data.email}</p>
                                <p className="ms-500 fs-12 ">Email</p>
                            </div>
                        </div>
                        <div className="col-lg-2 px-1 d-flex align-items-center mb-2">
                        <div className="d-flex flex-column justify-content-center ">
                                <p className="">{data.goalType}</p>
                                <p className="ms-500 fs-12 ">Goal</p>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 col-6 d-flex align-items-center justify-content-lg-end mb-2">
                            <div className="mx-2 d-flex flex-column justify-content-center align-items-lg-end">
                                <p className="">{data.created_at ? moment(data.created_at).format('DD MMM YYYY') : 'NA'}</p>
                                <p className="ms-500 fs-12">Enrolled On</p>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-3 col-6 d-flex align-items-center justify-content-lg-end mb-2">
                            <div className="d-flex flex-column justify-content-center align-items-lg-end">
                                <p className="">{data.created_at ? moment(data.created_at).format("hh:mm a") : 'NA'}</p>
                                <p className="ms-500 fs-12 ">Time</p>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>


        </>
    )
}
