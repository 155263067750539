import React, { useState, useEffect } from 'react'
import ApexChart from './ApexChart'
import check from '../../static/images/check.png'
import parrot from '../../static/images/parrot-1.png'
import EngagedTutor from './EngagedTutor'
import store from '../../redux/AppState'
import admin from '../../controller/admin';
import moment from 'moment';
import Backdrop from '../../component/Backdrop';
import { useParams } from 'react-router-dom';
import helper from '../../helper';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import TestimonialCard from './TestimonialCard'
import ConfirmPopup from '../../components/ConfirmPopup'
import { Toaster, toast } from 'react-hot-toast'
import avatar from '../../static/images/avatar.png'
import student from '../../controller/student'
import { FaBell } from 'react-icons/fa'
const map = {
    goalType: {
        1: "PROFESSIONAL DEVELOPMENT",
        2: "ACADMICS",
        3: "TRAVEL",
        4: "PERSONAL GROWTH",
    },
    level: {
        1: "Basic",
        2: "Intermediate",
        3: "Advanced"
    },
}
export default function StudentProfile() {
    const history = useHistory()
    const { userId } = useParams()
    const [data, setData] = useState()
    const [statsData, setStatsData] = useState()
    const [confirmPop, setConfirmPop] = useState({
        open: false,
        msg: '',
    })

    const [Sidebar, setSidebar] = useState(false);
    const [bilsum, setbilsum] = useState([])
    const [pageLoad, setpageLoad] = useState(true)
    const [testimonial, setTestimonial] = useState({
        load: true,
        error: '',
        data: [],
        page: 1,
        limit: 5,
        hide: false
    })

    // chart data 
    const [week, setWeek] = useState([])
    const [value, setValue] = useState([])
    const [chartLoad, setChartLoad] = useState(true)
    const [selectedDate, setSelectedDate] = useState(new Date())

    const cancelSubscription = async () => {
        const reqData = {
            "user_id": userId,
            "subscription_plan_id": uDet.subscriptionDetails.subscription_plan_id,
            "admin_id": helper.getid()
        }
        setpageLoad(true)
        await admin.cancelSubscription(reqData)
            .then((res) => {
                if (res.data.status) {
                    let temp = uDet
                    temp.subscriptionDetails.minutes_per_day = 0
                    setuDet(temp)
                    setConfirmPop({ ...confirmPop, open: false })
                    toast.success('subscription plan successfully cancelled')
                } else {
                    toast.error(res.data.error)
                }
                setpageLoad(false)
            })
    }

    useEffect(() => {
        apiCall()
        getStudentDetail()
        getChartData(new Date())
        getTestimonials(1)
        return () => { }
    }, [])

    const apiCall = async () => {
        await admin.getBillingSum(userId)
            .then((res) => {
                if (res.data.status) {
                    setbilsum(res.data.result)
                }
            })
    }

    const [uDet, setuDet] = useState()
    const getStudentDetail = async () => {
        await admin.getUserDetById(userId)
            .then((res) => {
                if (res.data.status) {
                    setData(res.data.result)
                    setuDet(res.data.result)
                }
              
                setpageLoad(false)
            })
    }

    const getTestimonials = async (page) => {
        let list = testimonial.data
        await student.getRatings(userId, page, testimonial.limit).then((res) => {
            if (res.data.status) {
                list = list.concat(res.data.result)
                setTestimonial({ ...testimonial, data: list, load: false })
                if (res.data.result.length < 5) {
                    setTestimonial({ ...testimonial, data: list, load: false, error: '', hide: true })
                }
            }
            else {
                setTestimonial({ ...testimonial, data: [], load: false, error: '' })
            }
        })
    }

    const getChartData = async (selectedDate) => {
        setChartLoad(true)
        let date = moment(selectedDate).format('YYYY-MM-DD')
        await student.getStudentWeeklyClasses(userId, date).then((res) => {
            if (res.data.status) {
                let list = []
                let list2 = []
                let map = res.data.result.learnAnalytics[0]
                for (const [key, value] of Object.entries(map)) {
                    list.push(key)
                    list2.push(value)
                }
                setStatsData(res.data.result.statsAndAnalytics)
                setWeek(list)
                setValue(list2)
            }

            setChartLoad(false)
        })
    }

    const updateStatus = async (isActive, status) => {
        await student.activeInactiveStudent({ studentId: parseInt(userId), isActive: Boolean(isActive), status }).then((res) => {
            if (res.data.status) {
                data.profileDetails.status = status
                let temp = {...data}
                setData(temp)
            }
        })
    }

    return (
        <>
            {!pageLoad && <>

                {<div className=" mx-1">
                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1 ">
                            <button className="btn btn-back mr-2 " onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>{data.profileDetails.name ? data.profileDetails.name : 'N/A'}</p>
                                <p className="sub-heading lh-15">Details</p>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            {data.profileDetails.status === "Active" ? <button className="btn btn-clear mx-2 d-lg-block ms-700 fs-14 d-md-block d-none" onClick={() => updateStatus(false, "Inactive")}>INACTIVE STUDENT</button>
                                : <button className="btn btn-tutor-profile mx-2 d-lg-block ms-700 fs-14 d-md-block d-none" onClick={() => updateStatus(true, "Active")}>ACTIVE STUDENT</button>
                            }
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>


                    <div className="">

                        <div className="row gx-0 mx-0">
                            <div className="col-lg-8 px-2 my-2 ">
                                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                                    <div className='d-flex justify-content-between mb-2 px-2 align-items-center'>
                                        <div>
                                            <p className="f-fred fs-18 px-2 pt-2">Learning analytics</p>
                                            <p className='sub-heading px-2'>{moment(selectedDate).format('MMMM YYYY')}</p>
                                        </div>
                                        <input type="month"
                                            max={moment('YYYY-MM-DD')}
                                            defaultValue={moment(selectedDate).format('YYYY-MM')}
                                            onChange={(e) => { getChartData(e.target.value); setSelectedDate(e.target.value) }}
                                            className='px-2 form-control ms-700 py-1'
                                            style={{ width: '200px' }}
                                        />
                                    </div>

                                    {!chartLoad && <ApexChart week={week} data={value} />}
                                </div>
                            </div>
                            <div className="col-lg-4 home2 px-2 my-2 ">
                                <div className="row gx-0 mx-0 ms-900">
                                    <div className="col-lg-12 home">
                                        <div className="row">
                                            <div className="col-lg-12 " >
                                                <p className="f-fred fs-18" style={{ color: "#1B221B" }}>Stats & analytics</p>
                                                <p className="sub-heading" style={{ fontSize: "14px" }}>Till now</p>
                                            </div>
                                        </div>
                                        <div className="row gx-0 mx-0 pb-4 pt-4">
                                            <div className="col-lg-6 col-md-6 col-6 px-0">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-0">
                                                        <div >
                                                            <p className="tx-lt-black-op-5 ms-900 pointer" style={{ fontSize: "16px" }}>Enrolled courses</p>
                                                            <h2 className="ms-500" style={{ fontWeight: "500" }}>{statsData && statsData.enrolled_courses}
                                                                <button className='btn bg-yellow py-1 text-white fs-12 ms-700 ml-2' onClick={() => history.push(`/${helper.getRole()}/dashboard/enrolled-courses/${userId}`)}>View</button>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6 px-0">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-0">
                                                        <div>
                                                            <p className="tx-lt-black-op-5 ms-900 pointer" style={{ fontSize: "16px" }} >Engaged tutors</p>
                                                            <h2 className="ms-500" style={{ fontWeight: "500" }}>{statsData && statsData.total_tutors}
                                                                <button className='btn bg-yellow py-1 text-white fs-12 ms-700 ml-2' onClick={() => setSidebar(true)}>View</button></h2>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gx-0 mx-0 pb-4 ">
                                            <div className="col-lg-6 col-md-6 col-6 px-0">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-0">
                                                        <div >
                                                            <a className="tx-lt-black-op-5 ms-900 pointer" style={{ fontWeight: "700", fontSize: "16px" }} >Total calls</a>
                                                            <h2 className="ms-500 fs-40" >{statsData && statsData.total_calls} </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6 px-0">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-0">
                                                        <div >
                                                            <a className="tx-lt-black-op-5 ms-900 pointer" style={{ fontWeight: "700", fontSize: "16px" }} >Certificates</a>
                                                            <h2 className="ms-500 fs-40">{statsData && statsData.certificates} </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gx-0 mx-0 ">
                                            <div className="col-lg-6 col-md-6 col-6 px-0">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-0">
                                                        <div >
                                                            <a className="tx-lt-black-op-5 ms-900 pointer" style={{ fontWeight: "700", fontSize: "16px" }} >Referrals</a>
                                                            <h2 className="ms-500 fs-40" >{statsData && statsData.referrals} </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-6 px-0">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-0">
                                                        <div >
                                                            <a className="tx-lt-black-op-5 ms-900 pointer" style={{ fontWeight: "700", fontSize: "16px" }} >Attendance</a>
                                                            <h2 className="ms-500 fs-40" >{statsData && statsData.attendance.toString().replace(/ /g, '')} </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* student profile details  */}
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-6 colmd-6 col-12 px-1 col-12 cpx-2 my-2 ">
                                {(uDet.subscriptionDetails && uDet.subscriptionDetails.minutes_per_day !== 0) &&
                                    <section className="row gx-0 mx-0" >
                                        <div className="col-lg-6 ms-500 col-md-6 col-12 mb-2" style={{ backgroundColor: "#FFCA00", borderRadius: "10px" }}>
                                            <div className='plan-summary py-3 px-3 h-100'>
                                                <div className='d-flex  justify-content-between align-items-center mb-3'>
                                                    <div>
                                                        <h4 className='ms-900 text-white'>{uDet.subscriptionDetails ? uDet.subscriptionDetails.subscription : "None"}</h4>
                                                        <p className='ms-500'>Currently active</p>
                                                    </div>
                                                    <div>
                                                        <img style={{ width: '30px', height: '30px' }} src={check} alt="" />
                                                    </div>
                                                </div>
                                                <div style={{ border: '2px dashed white' }}></div>
                                                <div className='mt-3'>
                                                    <p className='ms-900'>Plan summary</p>
                                                    <p>{uDet.subscriptionDetails ? uDet.subscriptionDetails.minutes_per_day : 0} min/day , {uDet.subscriptionDetails ? uDet.subscriptionDetails.days_per_week : 0} day/week | {uDet.subscriptionDetails && uDet.subscriptionDetails.commitment_level}</p>
                                                    <div className='my-2' style={{ border: '1px dashed black' }}></div>
                                                    <div className='d-flex justify-content-between ms-700'>
                                                        <span>Total cost</span>
                                                        <span>AED {uDet.subscriptionDetails ? uDet.subscriptionDetails.amount : 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 px-0 col-12 mb-2">
                                            <div className='px-2 h-100'>
                                                <div style={{ backgroundColor: '#F7F7F7' }} className="plan-minutes d-flex justify-content-between px-2 py-2 br-1">
                                                    <div className='d-flex ms-900 align-items-center py-3'>
                                                        <p className='fs-45  mr-2 '>{uDet.subscriptionDetails ? uDet.subscriptionDetails.minutes : 0}</p>
                                                        <p className='fs-20' style={{ transform: 'rotate(90deg)' }}>min</p>
                                                    </div>
                                                    <div className='all-center'>
                                                        <img style={{ width: '50px' }} src={parrot} alt="parrot" />
                                                    </div>
                                                </div>
                                                {uDet.subscriptionDetails.subscription_expiry_date &&
                                                    <>
                                                        <p className='mt-4 text-center ms-500'>Your next due date is </p>
                                                        <p className='ms-900 text-center'>
                                                            {moment(uDet.subscriptionDetails.subscription_expiry_date).format('DD MMM YYYY')}
                                                        </p>
                                                    </>
                                                }
                                                <div>
                                                    <button className='btn  btn-clear  f-fred py-2 my-1 fs-14 ms-700 w-100'
                                                        onClick={() => setConfirmPop({ ...confirmPop, open: true, msg: 'Do you want to cancel the plan?' })}
                                                    >CANCEL SUBSCRIPTION</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }

                                {/* profile card  */}
                                <section className="row gx-0 mx-0">
                                    <div className="col-lg-12 profile-bg">
                                        <div className="row gx-0 mx-0">
                                            <div className="col-lg-10 px-0">
                                                <p className="f-fred tx-lt-grey" style={{ fontSize: "24px" }}>Profile details</p>
                                            </div>
                                            <div className="col-lg-2 px-0 d-flex justify-content-end">
                                                <span ><img width="50px" height="50px" className="rounded-circle" src={uDet.profileDetails.profilePicture ? uDet.profileDetails.profilePicture : avatar} /></span>
                                            </div>
                                        </div>


                                        <div className="row ms-500 mx-0 gx-0 mt-3">
                                            <div className="col-lg-4 px-0 ms-500">
                                                <p style={{ color: "#434943" }}>Full name</p>
                                            </div>
                                            <div className="col-lg-8 text-right px-0 ms-900">
                                                <p style={{ color: "#434943" }}>{uDet.profileDetails.name}</p>
                                            </div>

                                        </div>
                                        <div className="col-12  border-dot my-1"></div>
                                        <div className="row ms-500 my-1 mx-0 gx-0">
                                            <div className="col-lg-4 px-0 px-0 ms-500">
                                                <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Email address</p>
                                            </div>
                                            <div className="col-lg-8 text-right  px-0 ms-900">
                                                <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{uDet.profileDetails.email}</p>
                                            </div>
                                        </div>

                                        <div className="col-12  border-dot my-1"></div>
                                        <div className="row ms-500 my-1 mx-0 gx-0">
                                            <div className="col-lg-4 px-0 ms-500">
                                                <p style={{ color: "#434943" }}>Country</p>
                                            </div>
                                            <div className="col-lg-8 text-right  px-0 ms-900">
                                                <p style={{ color: "#434943" }}>{uDet.profileDetails.country}</p>
                                            </div>
                                        </div>
                                        <div className="col-12  border-dot my-1"></div>
                                        <div className="row ms-500 my-1 mx-0 gx-0">
                                            <div className="col-lg-4 px-0 ms-500">
                                                <p className="ms-500">Account For</p>
                                            </div>
                                            <div className="col-lg-8 text-right  px-0 ms-900">
                                                <p className="ms-900  ml-auto">{uDet?.profileDetails?.selfAccount ? "Myself" : "Kids"}</p>
                                            </div>
                                        </div>
                                        <div className="col-12 border-dot my-1"></div>
                                        <div className="row ms-500 my-1 mx-0 gx-0">

                                            <div className="col-lg-4 px-0 ms-500">
                                                <p className="ms-500">Level</p>
                                            </div>
                                            <div className="col-lg-8 text-right  px-0 ms-900">
                                                <p className="ms-900  ml-auto">{map.level[uDet?.profileDetails?.levelType]}</p>
                                            </div>
                                        </div>
                                        <div className="col-12 border-dot my-1"></div>
                                        <div className="row ms-500 my-1 mx-0 gx-0">
                                           
                                            <div className="col-lg-4 px-0 ms-500">
                                                <p className="ms-500">Goal</p>
                                            </div>
                                            <div className="col-lg-8 text-right  px-0 ms-900">
                                                <p className="ms-900  ml-auto text-capitalize">{map.goalType[uDet?.profileDetails?.goalType]}</p>
                                            </div>
                                        </div>

                                    </div>
                                </section>

                            </div>

                            <div className="col-lg-6 col-12 px-2 my-2">


                                <h4 className='tx-lt-grey ms-900 mb-3'>Testimonials</h4>
                                {
                                    !testimonial.load && !testimonial.error && testimonial.data.map((result, index) => {
                                        return (
                                            <div key={`testimonial${index}`} className='mb-3'>
                                                <TestimonialCard
                                                    userName={result.name}
                                                    country={result.from}
                                                    date={result.createdOn}
                                                    rate={result.ratings}
                                                    review={result.review}
                                                    pic={result.profilePicture}
                                                />

                                            </div>
                                        )
                                    })
                                }
                                {/* testimonial erro handling  */}
                                {!testimonial.load && testimonial.error &&
                                    <p className='ms-700 my-2 text-center'>Something went wrong</p>
                                }
                                {!testimonial.load && !testimonial.error && testimonial.data.length === 0 && <div>
                                    <p className='ms-700 fs-16 text-center my-3'>No Testimonials Found</p>
                                </div>
                                }

                                {!testimonial.hide && <button
                                    onClick={() => {
                                        setTestimonial({ ...testimonial, page: testimonial.page + 1 });
                                        getTestimonials(testimonial.page + 1)
                                    }}
                                    className='btn load-more px-2 fs-14 ms-500 mt-3'>LOAD MORE</button>}


                            </div>
                        </div>

                        {/* Bill Summary  */}

                        <div className="row gx-0 mx-0">
                            <div className="col-lg-12 px-2 py-2 my-2">
                                <p className="fs-24 f-fred my-2 tx-lt-grey" >Billing summary</p>
                            </div>
                        </div>
                        <div className="row gx-0 mx-0 ">
                            <div className="col-lg-12 px-0">
                                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                                    <div className="row fs-14 gx-0 mx-0 px-2">
                                        {bilsum && bilsum.map((bil) => {
                                            return (
                                                <>
                                                    <div className="col-lg-2 col-md-4 col-6 px-0 my-1">
                                                        <p className="f-fred">{bil.subscription}</p>
                                                        <p className="ms-500 fs-14">Subscription</p>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-6 px-0 my-1">
                                                        <p className="f-fred ">{bil.minutes_per_day}</p>
                                                        <p className="ms-500 fs-14 ">Minutes/ day</p>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-6 px-0 my-1">
                                                        <p className="f-fred ">{bil.days_per_week}</p>
                                                        <p className="ms-500 fs-14 ">Days/ week</p>
                                                    </div>
                                                    <div className="col-lg-1 col-md-4 col-6 d-flex flex-column justify-content-center align-items-lg-end px-0 my-1">
                                                        <p className="f-fred">{bil.amount}</p>
                                                        <p className="ms-500 fs-14 ">Amount(AED)</p>

                                                    </div>
                                                    <div className="col-lg-1 col-md-4 col-6 d-flex flex-column justify-content-center align-items-lg-end px-0  my-1 ">
                                                        <p className="f-fred ">{bil.commitment_level}</p>
                                                        <p className="ms-500 fs-14 ">Cycle</p>

                                                    </div>

                                                    <div className="col-lg-2 col-md-4 col-6  d-flex flex-column justify-content-center align-items-lg-end px-0 my-1">
                                                        <p className="f-fred ">{moment(bil.created_at).format('MMM DD YYYY')}</p>
                                                        <p className="ms-500 fs-14 ">Enrolled on</p>

                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-6 d-flex flex-column justify-content-center px-0 align-items-lg-end my-1">
                                                        <p className="f-fred">{moment(bil.created_at).format('hh : mm A')}</p>
                                                        <p className="ms-500 fs-14 ">Time</p>

                                                    </div>

                                                </>)
                                        })}


                                        <div className="col-12">
                                            {bilsum && bilsum.length === 0 &&
                                                <p className='ms-700 fs-16 text-center my-3'>No plan found for the user</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
            </>}
            {pageLoad && <Backdrop />}

            {
                Sidebar &&
                <EngagedTutor
                    userId={data.profileDetails.user_id}
                    name={data.profileDetails.name}
                    CallClose={() => setSidebar(false)}
                />
            }



            <ConfirmPopup
                open={confirmPop.open}
                onOk={() => cancelSubscription()}
                onClose={() => setConfirmPop({ ...confirmPop, open: false })}
                msg={confirmPop.msg}

            />
        </>
    )
}

