import React, { useState } from 'react'
import { FiXCircle } from "react-icons/fi";
import EditUserProfile from '../adminDash/EditUserProfile';
import admin from '../controller/admin';
import { useEffect } from 'react';
import Backdrop from '../component/Backdrop';
import avatar from '../static/images/avatar.png'
import helper from '../helper';
import EditAdminProfile from './EditAdminProfile';
export default function ProfileDetails({ CallClose }) {
    const [Callpopup, setCallpopup] = useState({ open: false, id: 0, data: "" });
    const toggleCallpopup = (idValue, data) => {
        setCallpopup({ ...Callpopup, open: !Callpopup.open, id: idValue, data: data });
    }
    const [resData, setresData] = useState()
    const [pageLOad, setpageLOad] = useState(true)
    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await admin.getAdminProfile({id : helper.getid()})
            .then((res) => {
                if (res.data.status) {
                    setresData(res.data.result)
                }
                setpageLOad(false)
            })
    }
    return (
        <>
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10 px-0">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Profile <span style={{ color: "#089D12" }}> details</span></p>
                            <p className="ms-700 fs-14 sub-heading">My profile</p>
                        </div>
                        <div className="col-2 px-0">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={() => CallClose()} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    {!pageLOad  && <>
                        <div className="row gx-0 mx-0">
                                <div className="col-12 profile-bg">
                                    <div className="row gx-0 mx-0">
                                        <div className="col-lg-2 px-0 d-flex justify-content-end">
                                            <span className="pointer" ><img width="50px" height="50px" className="rounded-circle" src={resData && resData.profilePicture ? resData.profilePicture : avatar} /></span>
                                        </div>
                                    </div>
                                    <div className="row ms-500 mt-2 mx-0 gx-0">
                                        <div className="col-4 px-0 ms-500">
                                            <p style={{ color: "#434943" }}>Full name</p>
                                        </div>
                                        <div className="col-8 wrap text-right px-0 ms-900">
                                            <p style={{ color: "#434943" }}>{resData && resData.name}</p>
                                        </div>
                                    </div>
                                    <div className="col-12  border-dot my-1"></div>
                                    <div className="row ms-500 my-1 mx-0 gx-0">
                                        <div className="col-4 px-0 px-0 ms-500">
                                            <p className='ms-500' style={{ color: "#434943" }}>Email address</p>
                                        </div>
                                        <div className="col-8 overflow-elipse  px-0 ms-900">
                                            <p className='wrap' style={{ color: "#434943" }}>{resData && resData.email}</p>
                                        </div>
                                    </div>
                                    <div className="col-12  border-dot my-1"></div>
                                    <div className="row ms-500 my-1 mx-0 gx-0">
                                        <div className="col-6 px-0 ms-500">
                                            <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Phone number</p>
                                        </div>
                                        <div className="col-6 text-right  px-0 ms-900">
                                            <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{resData && resData.mobileNo} </p>
                                        </div>
                                    </div>
                                    <div className="col-12  border-dot my-1"></div>
                                    <div className="row ms-500 my-1 mx-0 gx-0">
                                        <div className="col-4 px-0 ms-500">
                                            <p style={{ color: "#434943" }}>User ID</p>
                                        </div>
                                        <div className="col-lg-8  px-0 ms-900">
                                            <p className=' text-right ' style={{ color: "#434943" }}>{resData && resData.id}</p>
                                        </div>
                                    </div>
                                    <div className="col-12  border-dot my-1"></div>
                                    <div className="row ms-500 my-1 mx-0 gx-0">
                                        <div className="col-4 px-0 ms-500">
                                            <p style={{ color: "#434943" }}>Role</p>
                                        </div>
                                        <div className="col-8 text-right  px-0 ms-900">
                                            <p className='text-capitalize' style={{ color: "#434943" }}>{helper.getRole()}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-12 py-4 w-100">
                                    <button className="btn btn-edit ms-700 fs-14 mx-auto d-block" onClick={() => { toggleCallpopup(resData.id, resData) }}>Edit profile</button>
                                </div>
                          
                        </div>
                    </>}



                    {pageLOad && <Backdrop />}
                    {Callpopup.open &&
                        <EditAdminProfile
                            data={resData}
                            CallClose={() => { toggleCallpopup() }}
                            OnEditProfile={() => { apiCall() }}
                        />}
                </div>
            </div>
        </>
    )
}
