import React from 'react';
import moment from 'moment';
import avatar from '../../static/images/avatar.png'
import ModalImage from "react-modal-image";

export default function TutorCard({ tut, onClick }) {
    return (

        <>
            <div className="quote-card cur-pointer f-fred fs-14 bg-smoke border-radius-1 py-2 my-2 ">
                <div className="row gx-0 mx-0 tx-lt-grey">
                    <div className="col-lg-1 col-md-4 col-12 all-center wrap px-1 my-2">
                        <button className={`btn ${tut.profileStatus === "Approved" ? ' btn-close' : 'btn-open'} w-100  fs-16 py-1 px-2 ms-700 `}>{tut.profileStatus}</button>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6 px-2 my-2 d-flex align-items-center">
                        <span className="pointer" >
                            <ModalImage
                                className="rounded-circle wh-40"
                                hideDownload={true}
                                hideZoom={true}
                                small={tut.profilePicture ? tut.profilePicture : avatar}
                                large={tut.profilePicture ? tut.profilePicture : avatar}
                                alt={tut.name}
                            />
                            {/* <img width="40px" height="40px" className="rounded-circle" src={tut.profilePicture ? tut.profilePicture : avatar} /> */}
                        </span>
                        <div className="px-2">
                            <p className="">{tut.name}</p>
                            <p className="ms-500 fs-12">Tutor</p>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-4 col-6 d-flex my-2 align-items-center ">
                        <div className="px-2">
                            <p className="">{tut.from ? tut.from : 'NA'}</p>
                            <p className="ms-500 fs-12">Country</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-12 my-2 d-flex align-items-center justify-content-lg-start  justify-content-between ">
                        <div className=" px-2">
                            <p className="">{tut.ratings ? parseFloat(tut.ratings).toFixed(1) : 0}</p>
                            <p className="ms-500 fs-12 ">Rating</p>
                        </div>
                        <div className="px-2">
                            <p className="">{tut.rate}</p>
                            <p className="ms-500 fs-12 ">Rate/minute(SAR)</p>
                        </div>
                        <div className="px-2">
                            <p className="">{tut.totalStudent}</p>
                            <p className="ms-500  fs-12">Students</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 my-2 px-0 align-items-center d-lg-flex d-md-flex justify-content-md-between d-none">
                        <div className="px-2 d-flex align-items-lg-end align-items-start flex-column">
                            <p className="">{tut.amount}</p>
                            <p className="ms-500 fs-12">Earned(SAR)</p>

                        </div>
                        <div className=" px-2 d-flex align-items-lg-end align-items-start flex-column">
                            <p className="">{moment(tut.createdOn).format('DD MMM YYYY')}</p>
                            <p className="ms-500 fs-12">Added on</p>

                        </div>
                        <div className=" px-2 d-flex align-items-lg-end align-items-between flex-column ">
                            <p className="">{moment(tut.createdOn).format("hh:mm a")}</p>
                            <p className="ms-500 fs-12">Time</p>

                        </div>
                    </div>
                    <div className="col-lg-1 px-1 my-2 d-flex align-items-center">
                        <div className="my-1 w-100">
                            <button className="btn btn-detail br-1  w-100 py-1 px-2 ms-900 fs-14" onClick={() => { onClick(tut.id) }}>Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}
