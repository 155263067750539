import React, { useState, useEffect } from 'react'
import { FiXCircle } from "react-icons/fi";
import manager from '../controller/manager';
import helper from '../helper';
import avatar from '../static/images/avatar.png'
import EditManagerDetails from './EditManagerProfile';
export default function ManagerProfile({ CallClose, onEdit }) {
    const [state, setState] = useState({
        data: '',
        pageload: true
    })
    const [Callpopup, setCallpopup] = useState({ open: false, id: 0, data: "" });
    const toggleCallpopup = (idValue, data) => {
        setCallpopup({ ...Callpopup, open: !Callpopup.open, id: idValue, data: data });
    }

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = async () => {
        await manager.getManagerDetails(helper.getid()).then((res) => {
            if (res.data.status) {
                setState({ ...state, pageload: false, data: res.data.result.length > 0 && res.data.result[0] })
              
            }
        })
    }

    return (
        <>
            <div className="  all-center popup-modal">
                {!state.pageload &&
                    <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                        <div className="row gx-0 mx-0">
                            <div className="col-10">
                                <p className="f-fred" style={{ fontSize: "24px" }}>Manager<span style={{ color: "#089D12" }}> details</span></p>
                                <p className="ms-700 fs-14 " style={{ color: "#434943" }}>SayDhad manager</p>
                            </div>
                            <div className="col-2">
                                {/* <i class="far fa-times-circle float-right tx-lt-black pt-3"style={{fontSize:"24px"}}></i> */}
                                <FiXCircle className="pointer float-right " size="2rem" onClick={() => { CallClose() }} style={{ color: "lightgray" }} />
                            </div>
                        </div>


                        <div className="row gx-0 mx-0 edit-profile f-fred">

                            <div className="col-lg-12 edit-input mt-4">

                                <label class="switch">
                                    <input type="checkbox" defaultChecked={state.data.isActive ?  true  :false} />
                                    <span class="slider round"></span>
                                </label>
                                <span className="pl-2 fs-18">{state.data.isActive ? 'Active' : 'Inactive'}</span>

                            </div>

                        </div>
                        <section className="row gx-0 mx-0">
                            <div className="col-lg-12 profile-bg">
                                <div className="row gx-0 mx-0">
                                    <div className="col-lg-2 px-0 d-flex ">
                                        <span className="pointer" ><img width="50px" height="50px" className="rounded-circle" src={state.data.profilePicture ? state.data.profilePicture : avatar} /></span>
                                    </div>
                                </div>
                                <div className="row ms-500 mt-2 mx-0 gx-0">
                                    <div className="col-6 px-0 ms-500">
                                        <p style={{ color: "#434943" }}>Full name</p>
                                    </div>
                                    <div className="col-6 wrap text-right px-0 ms-900">
                                        <p style={{ color: "#434943" }}>{state.data.name}</p>
                                    </div>
                                </div>
                                <div className="col-12  border-dot my-1"></div>
                                <div className="row ms-500 my-1 mx-0 gx-0">
                                    <div className="col-4 px-0 px-0 ms-500">
                                        <p className='ms-500' style={{ color: "#434943" }}>Email address</p>
                                    </div>
                                    <div className="col-8 overflow-elipse  px-0 ms-900">
                                        <p className='wrap text-right' style={{ color: "#434943" }}>{state.data.email}</p>
                                    </div>
                                </div>
                                <div className="col-12  border-dot my-1"></div>
                                <div className="row ms-500 my-1 mx-0 gx-0">
                                    <div className="col-6 px-0 ms-500">
                                        <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Phone number</p>
                                    </div>
                                    <div className="col-6 text-right  px-0 ms-900">
                                        <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{state.data.mobileNo} </p>
                                    </div>
                                </div>
                                <div className="col-12  border-dot my-1"></div>
                                <div className="row ms-500 my-1 mx-0 gx-0">
                                    <div className="col-6 px-0 ms-500">
                                        <p style={{ color: "#434943" }}>Manager ID</p>
                                    </div>
                                    <div className="col-6 text-right  px-0 ms-900">
                                        <p style={{ color: "#434943" }}>{state.data.managerId}</p>
                                    </div>
                                </div>
                                <div className="col-12  border-dot my-1"></div>
                                <div className="row ms-500 my-1 mx-0 gx-0">
                                    <div className="col-6 px-0 ms-500">
                                        <p style={{ color: "#434943" }}>Role</p>
                                    </div>
                                    <div className="col-6 text-right  px-0 ms-900">
                                        <p style={{ color: "#434943" }}>Manager</p>
                                    </div>
                                </div>
                                <div className="col-12  border-dot my-1"></div>
                                <div className="row ms-500 my-1 mx-0 gx-0">
                                    <div className="col-12 px-0 ms-500">
                                        <p style={{ color: "#434943" }}>Access module</p>
                                    </div>
                                    <div className="col-12 d-flex  px-0 ms-900">
                                        <p style={{ color: "#434943" }}>{state.data.accessModule}</p>
                                    </div>
                                </div>

                            </div>
                        </section>
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center align-content-center pt-2 mt-2">
                                <button className="btn btn-edit" onClick={() => { toggleCallpopup(state.data.id, state.data) }}>Edit profile</button>
                                {Callpopup.open &&
                                    <EditManagerDetails
                                        id={Callpopup.id}
                                        data={Callpopup.data}
                                        CallClose={() => { toggleCallpopup() }}
                                        onUpdate={(data) => { setState({ ...state, data: data, pageload: false }) }}

                                    />}
                            </div>
                        </div>
                    </div>}
            </div>

        </>
    )
}
