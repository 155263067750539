import React, { useEffect, useState, useRef } from 'react';
import ProfileIcon from '../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import admin from '../../controller/admin';
import helper from '../../helper/index';
import { toast } from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
import NewSyllabusCard from './NewSyllabusCard';
import crossImg from '../../static/images/closeIcon.png'
import PreviewSlides from './PreviewSlides';
import logo from '../../static/images/logo.png'
import loader from '../../static/images/load.gif'
import { FaBell } from 'react-icons/fa';
import { fileUploadToS3 } from '../../helper/fileupload';
export default function UploadSyllabus() {
    const history = useHistory()
    const [pageLoad, setpageLoad] = useState(true)
    const [loader, setLoader] = useState(false);
    const [courseData, setCourseData] = useState(null);
    const [uploadedNo, setUploadedNo] = useState(0)
    const [fileSize, setFileSize] = useState(0)
    const [noOfSlides, setNoOfSlides] = useState(0)

    const [state, setState] = useState({
        selectedSlide: [],
        selectedSlideBlob: []
    })
    const [previewOpen, setPreviewOpen] = useState({
        open: false,
        index: 0
    })
    const formRef = useRef()
    useEffect(() => { apiCall() }, [])

    const apiCall = async () => {
        const url = new URLSearchParams(window.location.search)
        await admin.getCourseById(url.get('id'))
            .then((res) => {
                if (res.data.status) {
                    setCourseData(res.data.result.length > 0 ? res.data.result[0] : null)
                }
                setpageLoad(false)
            })
    }

    const addSyllabus = async () => {
        let url = new URLSearchParams(window.location.search)
        const formData = new FormData(formRef.current)
        if (!url.get('id')) {
            return toast.error("Invalid course ID")
        }
        if (!formData.get('name')) {
            return toast.error("please enter chapter name")
        }
        if (!formData.get('durationInMinutes')) {
            return toast.error("please enter chapter duration")
        }
        if (isNaN(formData.get('durationInMinutes'))) {
            return toast.error("please enter valid duration")
        }
        formData.append('courseId', url.get('id'))
        formData.append('adminId', helper.getid())
        let list = [], previewCount = 0, find = false;

        state.selectedSlideBlob.some((data, index) => {
            if (!data.title) {
                find = true
                toast.error(`please add title on ${index + 1} slide`)
                return true
            }
            if (data.preview === true) {
                previewCount++;
            }
            list.push({ index: index, isPreview: data.preview, title: data.title, fileURL: null })
        })
        if (find) { return }
        if (previewCount < 1) {
            toast.error(`please select atleast 1 slides as preview`)
            return
        }
        if (calSize() > 25) {
            toast.error("File size should be less than 25 Mb")
            return
        }
        setLoader(true)
        let index = 0
        for (const iterator of state.selectedSlideBlob) {
            let fileURL = await fileUploadToS3("courses/" + url.get('id'), iterator.file)
            console.log("AWS URL-->", fileURL)
            list[index].fileURL = fileURL
            index++;
            setUploadedNo(index)
        }
        formData.append('fileInfo', JSON.stringify(list))
        await admin.addSyllabus(formData)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, selectedSlide: [], selectedSlide: [] })
                    setPreviewOpen({ ...previewOpen, open: false, index: 0 })
                    toast('Syllabus successfully uploaded')
                    formRef.current.reset()
                    setFileSize(0)
                    setNoOfSlides(0)
                    apiCall()
                } else {
                    toast(res.data.error)
                }
                setLoader(false)
            })
    }

    const addSlides = (data) => {
        let list = []
        let orgList = []
        let sum = 0, index = 0
        for (const [key, value] of Object.entries(data)) {
            index++;
            orgList.push({
                file: value,
                preview: false,
                title: ''
            })
            sum = sum + value.size / 1024;
            list.push(URL.createObjectURL(value))
        }
        setFileSize(sum)
        setNoOfSlides(index)
        setState({
            ...state,
            selectedSlide: list,
            selectedSlideBlob: orgList
        })
    }

    const addMoreSlides = (data) => {
        let list = [...state.selectedSlide]
        let sum = state.fileSize, index = state.selectedSlide.length
        let orgList = [...state.selectedSlideBlob]

        for (const [key, value] of Object.entries(data)) {
            index++;
            list.push(URL.createObjectURL(value))
            orgList.push({
                file: value,
                preview: false,
                title: ''
            })
            sum = sum + value.size / 1024;
        }
        setFileSize(sum)
        setNoOfSlides(index)
        setState({
            ...state,
            selectedSlide: list,
            selectedSlideBlob: orgList
        })
    }

    const removeSlide = (index) => {
        let orgList = [...state.selectedSlideBlob]
        let list = [...state.selectedSlide]
        list.splice(index, 1)
        orgList.splice(index, 1)
        let sum = parseInt(fileSize) - parseInt(state.selectedSlideBlob[index].file.size) / 1024
        setState({
            ...state,
            selectedSlide: list,
            selectedSlideBlob: orgList
        })
        setNoOfSlides(state.selectedSlide.length - 1)
        setFileSize(sum)
    }

    const calSize = () => {
        let size = parseInt(fileSize / 1024).toString() + "." + parseInt(parseInt(fileSize % 1024) / 10).toFixed(0).toString()
        return parseFloat(size);
    }

    const addPreview = (index) => {
        let list = [...state.selectedSlideBlob]
        list[index].preview = !list[index].preview
        setState({
            ...state,
            selectedSlide: state.selectedSlide,
            selectedSlideBlob: list
        })
    }

    const addSlideTitle = (index, title) => {
        let list = [...state.selectedSlideBlob]
        list[index].title = title
        setState({
            ...state,
            selectedSlide: state.selectedSlide,
            selectedSlideBlob: list
        })
    }

    return (
        <>
            <header className='row gx-0 mx-0 mb-3 py-1'>
                <div className="col-lg-4 pl-1 col-md-5 col-6 d-flex align-items-center">
                    <div className='mr-2'>
                        <button className="btn btn-back  fs-12" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                    </div>
                    <div className='d-flex flex-column'>
                        <p className="mb-1 fs-20 f-fred">Add <span className="tx-green">Syllabus</span></p>
                        <p className="lh-15 sub-heading text-capitalize">{courseData && courseData.course[0].title}</p>
                    </div>
                </div>
                <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center">
                    <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                        <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                    </p>
                    <ProfileIcon />
                </div>
            </header>
            <div className='mx-1'>
                <div className="row gx-0 mx-0">
                    <section className="col-lg-8 px-0">
                        <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                            <div className="row gx-0 mx-0">
                                <div className="col-12 px-1">
                                    <input
                                        maxLength={100}
                                        name="name"
                                        className='px-2 py-1 my-2 fs-16 ms-700'
                                        type="text"
                                        placeholder="Chapter Name"
                                        style={{ border: "1px solid lightgray", width: "100%", borderRadius: "8px", border: "1px solid #EAEAEA", backgroundColor: "#F9F9F9", height: "38px" }} />
                                </div>
                                <div className="col-6 px-1">
                                    <input
                                        name="durationInMinutes"
                                        className='px-2 py-1 my-2 fs-16 ms-700'
                                        type="number"
                                        placeholder="Syllabus duration (in min)"
                                        style={{ border: "1px solid lightgray", width: "100%", borderRadius: "8px", border: "1px solid #EAEAEA", backgroundColor: "#F9F9F9", height: "38px" }} />
                                </div>
                                <div className="col-12 px-1">
                                    {noOfSlides > 1 && <p className='ms-700 my-2'>File size : {calSize()} MB <span className='ms-500 tx-lt-black'>(max. 25 MB)</span> <span className='mx-4'>Syllabus length : {noOfSlides}</span></p>}
                                </div>
                                <div className="col-12 px-1">
                                    <div className="row gx-0 mx-0">
                                        {state.selectedSlide.map((data, index) =>
                                            <div key={index} className='col-lg-4  pl-0 mb-3' >
                                                <div className='p-rel'>
                                                    <img className='w-100 pointer b-grey' onClick={() => { setPreviewOpen({ ...previewOpen, open: true, index: index }) }} src={data} alt="" />
                                                    <span className='p-abs tp-0 rt-0 m-1 pointer hover-shadow' onClick={() => removeSlide(index)}><img src={crossImg} alt="" /></span>
                                                    <div style={{ backgroundColor: '#adb2b4ed', borderRadius: '0 0 10px 10px' }} className='p-abs d-flex justify-content-between align-items-center  w-100 bt-0 rt-0 px-2 py-1 pointer' >
                                                        <section className='all-center'>
                                                            <input id={`prev${index}`} checked={state.selectedSlideBlob.some((data, indexNo) => indexNo === index && data.preview)} style={{ height: '15px', width: '15px' }} type={'checkbox'} onChange={() => addPreview(index)} />
                                                            <label htmlFor={`prev${index}`} className='ml-2 my-0 pointer  ms-700 fs-14'>Mark as preview</label>
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className='tutor-library'>
                                                    <input type="text" value={state.selectedSlideBlob[index].title} onChange={(e) => addSlideTitle(index, e.target.value)} placeholder='Add slide title' className='input-box w-100 my-2 px-2 ms-700' />
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 my-1 py-1 pl-0">
                                    {state.selectedSlide.length === 0 ? <label htmlFor="syllabus" className='all-center w-100 my-0 py-0 btn btn-update'>SELECT SLIDES</label> :
                                        <label htmlFor="syllabus" className='btn btn-tutor-profile fs-14 f-fred ls text-white all-center'>UPLOAD MORE SLIDES</label>}
                                    <input type="file" multiple accept=".jpeg,.jpg,.png" className='d-none' id="syllabus" onChange={(e) => {
                                        if (state.selectedSlide.length === 0) {
                                            addSlides(e.target.files)
                                        } else {
                                            addMoreSlides(e.target.files)
                                        }
                                    }} />
                                </div>
                                <div className='col-lg-4 my-1 py-1 pl-0'>
                                    <button className='btn btn-tutor-profile fs-14 f-fred ls  text-white' style={{ width: '200px' }} onClick={addSyllabus}>SUBMIT</button>
                                </div>
                            </div>
                        </form>
                    </section>
                    <section className="col-lg-4 h-100 b-grey">
                        <p className='f-fred tx-lt-grey fs-20 my-2 mb-3'>SYLLABUS</p>
                        {courseData && courseData.course[0].syllabusList.map((syl, index) => {
                            return (<>
                                <div className=" pr-2 my-2">
                                    <NewSyllabusCard
                                        name={syl.name}
                                        slides={syl.noOfSlides}
                                        lessonNo={index + 1}
                                        file={syl.slideDetails}
                                    />
                                </div>
                            </>)
                        })}
                        {
                            courseData && courseData.course[0].syllabusList.length === 0 &&
                            <p className='ms-700 fs-18 my-3 text-center'>
                                You have not add any syllabus till now.
                            </p>
                        }
                    </section>
                </div>
            </div>
            {previewOpen.open && <PreviewSlides cb={() => { setPreviewOpen({ ...previewOpen, open: false }) }} data={state.selectedSlide} startIndex={previewOpen.index} />}
            {pageLoad && <Backdrop />}
            {loader && <Loader total={noOfSlides} completed={uploadedNo} />}

        </>
    )
}


const Loader = ({ completed, total }) => {
    return (<>
        <div className="popup-modal all-center">
            <div className="popup-modal-body sm p-2">
                <div className='my-3'>
                    <img className='d-block mx-auto' style={{ width: '200px' }} src={logo} alt="parrot" />
                </div>
                <p className='ms-900 fs-18 my-3 text-center'>Please wait we are uploading the syllabus</p>
                <p className='ms-900 fs-18 my-3 text-center'>{completed + "/" + total}</p>
                <div className='my-2'>
                    <img className='d-block mx-auto' style={{ width: '200px' }} src={loader} alt="parrot" />
                </div>
            </div>
        </div>
    </>)
}
