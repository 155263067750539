import React from 'react';
export default function ViewCertificate({ open, pic,onClose }) {
    return (
        <>
            {open &&
                <div className="popup-modal h-100 all-center" onClick={onClose}>
                    <div className="popup-modal-body md">
                       

                        <section className=' all-center'>
                            <img className='br-1' style={{maxWidth:'100%'}} src={pic} alt="" />
                        </section>
                   

                    </div>
                </div>
            }
        </>
    )

}


