import React from 'react';
import avatar from '../../static/images/avatar.png'
import ReactStars from "react-rating-stars-component";
export default function TopUserCard({ name, pic, flag, rating,courses,onClick }) {

    return (<>

        <div>
            <div className="quote-card pointer cur-pointer bg-smoke border-radius-1 py-2 my-2 " onClick={onClick}>
                <div className="row gx-0 mx-0">
                    <div className="col-2  px-2 my-1">
                        <span className="pointer" ><img width="40px" height="40px" className="rounded-circle" src={pic ? pic : avatar} alt="" /></span>
                    </div>
                    <div className="col-5 fs-14 px-2 my-1">

                        {rating && <ReactStars
                            edit={false}
                            value={rating}
                            count={5}
                            size={15}
                            activeColor="#ffcb00"
                        />}
                        <p className="f-fred ">{name}</p>


                    </div>

                    <div className="col-5 fs-12 px-1 my-1 d-flex justify-content-end">
                    {rating ?
                        <div>
                            <p className="f-fred text-right">0</p>
                            <p className="ms-500 text-right tx-lt-black">Earnings (SAR)</p>

                        </div> : 
                        <div>
                        <p className="f-fred text-right">{courses}</p>
                        <p className="ms-500 text-right tx-lt-black">Enrolled (courses)</p>

                    </div>}
                        <div className="my-1 ml-2">
                            <span className=" text-right pointer" ><img width="24px" height="24px" className="rounded-circle" src={flag} alt="" /></span>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </>)
}
